// https://foundry41-landing-page-server.herokuapp.com/graphql

import {ApolloClient} from 'apollo-client';
import {InMemoryCache} from 'apollo-cache-inmemory';
import fetch from 'isomorphic-fetch'; // https://github.com/apollographql/apollo-link/issues/513#issuecomment-368234260
import {HttpLink} from 'apollo-link-http';

// create apollo cache and setup normalization features with dataIdFromObject
const cache = new InMemoryCache({});

// create apollo-client instance
const client = new ApolloClient({
  link: new HttpLink({
    uri: !process.env.REACT_APP_API_HOST
      ? 'http://localhost:8080/graphql'
      : process.env.REACT_APP_API_HOST, // 'https://heyned.meteorapp.com/graphql',
  }),
  fetch,
  cache,
  connectToDevTools: true,
});

// export the client to be used by the app
export default client;
