import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import client from '../src/ApolloClient/index.js';
import {ApolloProvider, useMutation} from 'react-apollo';
import SUBMIT_HEYYAK from '../src/ApolloClient/Mutations/submitHeyyak.js';
import {validate} from 'email-validator';

const BottomItem = ({title, subtitle, src}) => (
  <div className="w-1/3 text-left">
    {' '}
    <img className="h-12 w-12 rounded-full" src={src} alt="" />
    <div className="p-2">
      <p className="font-semibold text-white leading-tight mb-1 mt-1">
        {title}
      </p>
      <p className="text-sm text-gray-500 leading-tight">{subtitle}</p>{' '}
    </div>
  </div>
);

const ContentContainer = styled.div`
  padding-right: 24px;
  @media only screen and (min-width: 1600px) {
    padding-left: 64px;
  }
`;

const Hero = () => {
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.mixpanel.track('Page Load', {genre: 'hip-hop'});
  }, []);

  const [submitHeyyak] = useMutation(SUBMIT_HEYYAK);
  const onSubmit = async e => {
    e.preventDefault();
    try {
      if (!email) return null;
      if (email.length === 0) return null;
      if (!validate(email)) return null;
      setLoading(true);
      let res = await submitHeyyak({
        variables: {
          email,
        },
      });
      window.mixpanel.track('Email submit', {email});
      if (res.data.submitHeyyak.success) {
        setEmail('');
        setLoading(false);
        return setSuccess(true);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <div className="relative min-h-screen overflow-hidden bg-gray-900 lg:bg-gray-300">
      {/* <div
        className="hidden lg:block absolute scroll-bg "
        style={{
          height: '200%',
          width: '200%',
          top: '-5%',
          left: '-100%',
          backgroundRepeat: 'no-repeat',
          backgroundImage: `url('https://images.unsplash.com/photo-1515524738708-327f6b0037a7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80')`,
        }}
      ></div> */}
      <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <img
          class="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
          src="https://images.unsplash.com/photo-1551434678-e076c223a692?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80"
          alt=""
        />
      </div>
      <div
        className="relative min-h-screen lg:min-w-3xl xl:min-w-4xl lg:flex lg:items-center lg:justify-center lg:w-3/5 lg:py-20  lg:pl-6 lg:pr-8 bg-no-repeat"
        style={{
          backgroundImage: `url('./angled-background.svg')`,
          backgroundSize: '100% auto',
          backgroundPosition: '-5px -5px',
          paddingTop: 32,
        }}
      >
        <ContentContainer>
          <div className="px-6 pt-4 pb-12 md:max-w-3xl md:mx-auto lg:mx-0 lg:max-w-none lg:pt-0 lg:pb-12">
            <div className="flex items-center justify-between">
              <div>
                <img
                  className="h-6 lg:h-9 xl:h-10"
                  src="/joltflow-logo.svg"
                  alt="Tailwind UI"
                />
              </div>
              <div></div>
            </div>
          </div>
          <div className="px-6 md:max-w-3xl md:mx-auto lg:mx-0 lg:max-w-none">
            <p className="text-sm font-normal text-gray-300 uppercase tracking-wider">
              Coming Soon
            </p>
            <h1 className="mt-3 text-3xl leading-9 font-semibold font-display text-white sm:mt-6 sm:text-4xl sm:leading-10 xl:text-5xl xl:leading-none">
              Launch new products,
              <br className="hidden sm:inline" />
              <span style={{color: '#7B93DB'}}> the right way.</span>
            </h1>
            <p className="mt-2 text-lg leading-7 text-gray-300 sm:mt-3 sm:text-xl sm:max-w-xl xl:mt-4 xl:text-1xl xl:max-w-2xl">
              We’re building a marketplace specifically for connecting
              entrepreneurs, startups and companies looking to develop new
              digital products with those who specialize in making them. Submit
              your project and we’ll match you with three quality bids from
              US-based freelancers and agencies.
            </p>
            <div className="mt-8 sm:max-w-lg sm:mx-auto sm:text-center lg:text-left lg:mx-0">
              {!success ? (
                <>
                  <p className="text-base font-medium text-gray-400">
                    Sign up for updates, early access, and to find out when it’s
                    ready.
                  </p>
                  <div className="mt-3 sm:flex">
                    <input
                      aria-label="Email"
                      className="appearance-none block w-full px-3 py-3 border border-gray-300 text-base leading-6 rounded-md placeholder-gray-500 shadow-sm focus:outline-none focus:placeholder-gray-400 focus:shadow-outline focus:border-blue-300 transition duration-150 ease-in-out sm:flex-1"
                      placeholder="Enter your email"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                    />
                    <button
                      onClick={onSubmit}
                      disabled={loading}
                      className="mt-3 w-full px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-800 shadow-sm hover:bg-gray-700 focus:outline-none focus:shadow-outline active:bg-gray-900 transition duration-150 ease-in-out sm:mt-0 sm:ml-3 sm:flex-shrink-0 sm:inline-flex sm:items-center sm:w-auto"
                    >
                      {!loading ? 'Notify me' : '...'}
                    </button>
                  </div>
                  <p className="mt-3 text-sm leading-5 text-gray-500">
                    We care about the protection of your data. Read our{' '}
                    <a
                      href="https://www.termsfeed.com/privacy-policy/861caffdd4773f0b97bc393534ac8b70"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="font-medium text-gray-400 underline cursor-pointer"
                    >
                      {' '}
                      Privacy Policy
                    </a>
                    .
                  </p>
                </>
              ) : (
                <div>
                  <div style={{height: 118}} className="flex items-center">
                    <div class="flex items-center justify-center h-5 w-5 rounded-full bg-green-100 mr-2">
                      <svg
                        class="h-6 w-6 text-green-600"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M5 13l4 4L19 7"
                        ></path>
                      </svg>
                    </div>
                    <p className="font-semibold text-white leading-tight">
                      We've added you to our list!
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="hidden md:block px-6 py-8 sm:pt-12 md:max-w-3xl md:mx-auto lg:mx-0 lg:max-w-full lg:py-0 lg:pt-12">
            <p className="text-sm font-semibold text-gray-300 uppercase tracking-wider">
              What can you do
            </p>
            <div>
              <div className="mt-4 sm:flex">
                <BottomItem
                  title="Minimum Viable Products"
                  src="/MVP.svg"
                  subtitle="Don’t become another failed startup statistic. Get your MVP made the right way, the first time."
                />
                <BottomItem
                  title="New Product Launches"
                  src="/NewProduct.svg"
                  subtitle="Are you an existing company with  a new idea but the in-house dev team doesn’t have the bandwidth? "
                />
                <BottomItem
                  title="Internal Software Tools"
                  src="/InternalSoftware.svg"
                  subtitle="Need some custom software for your internal team? Don’t want to build and manage it in-house? "
                />
              </div>
            </div>
          </div>
        </ContentContainer>
      </div>
    </div>
  );
};

export default () => (
  <ApolloProvider client={client}>
    <Hero />
  </ApolloProvider>
);
