// TOP LEVEL IMPORTS
import gql from 'graphql-tag';

export default gql`
  mutation submitHeyyak($email: String!) {
    submitHeyyak(email: $email) {
      success
    }
  }
`;
